import type { RouteRecordRaw } from 'vue-router';

export const ADMIN_ROUTE: RouteRecordRaw = {
  path: '/admin',
  name: 'public/admin',
  component: () => import('./AdminView.vue'),
  children: [
    {
      path: '',
      name: 'public/admin.index',
      component: () => import('./home/AdminHomeView.vue'),
    },
    // {
    //   path: 'card-priority',
    //   name: 'public/admin.card-priority',
    //   component: () => import('./cardPriority/adminCardPriorityRouteView.vue'),
    // },
    {
      path: 'card-management',
      name: 'public/admin.card-management',
      component: () =>
        import('./cardManagement/adminCardManagementRouteView.vue'),
    },
    {
      path: 'rank-management',
      name: 'public/admin.rank-management',
      component: () =>
        import('./rankManagement/adminRankManagementRouteView.vue'),
    },
    {
      path: 'user-management',
      name: 'public/admin.user-management',
      component: () =>
        import('./userManagement/adminUserManagementRouteView.vue'),
    },
    {
      path: 'card-content',
      name: 'public/admin.card-content',
      component: () => import('./cardContent/cardContentRouteView.vue'),
    },
    {
      path: 'template',
      name: 'public/admin.template',
      component: () => import('./template/templateRouteView.vue'),
    },
    {
      path: 'edit-template/:id',
      name: 'public/admin.edit-template',
      component: () => import('./editTemplate/EditTemplateView.vue'),
    },
    {
      path: 'edit-template',
      name: 'public/admin.new-template',
      component: () => import('./editTemplate/EditTemplateView.vue'),
    },
  ],
};
