import type { RouteRecordRaw } from 'vue-router';

export const SETTING_ROUTE: RouteRecordRaw = {
  path: '/setting',
  name: 'private/setting.index',
  component: () => import('./SettingView.vue'),
  children: [
    {
      path: '',
      name: 'private/setting.info',
      component: () => import('./info/SettingInfo.vue'),
    },
    {
      path: 'notification',
      name: 'private/setting.notification',
      component: () => import('./notification/SettingNotification.vue'),
    },
    {
      path: 'language',
      name: 'private/setting.language',
      component: () => import('./language/SettingLanguage.vue'),
    },
    {
      path: 'security',
      name: 'private/setting.security',
      component: () => import('./security/SettingSecurity.vue'),
    },
    {
      path: 'support',
      name: 'private/setting.support',
      component: () => import('./support/SettingSupport.vue'),
    },
    {
      path: 'block',
      name: 'private/setting.block',
      component: () => import('./block/SettingBlock.vue'),
    },
  ],
};
