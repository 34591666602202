import type { RouteRecordRaw } from 'vue-router';

const ROUTE_PATH = 'P_:NV117([A-Z0-9]{6,})';

export const WALL_ROUTE: RouteRecordRaw[] = [
  {
    path: `/${ROUTE_PATH}`,
    name: 'public/wall.index',
    component: () => import('./WallView.vue'),
  },

  {
    path: `/${ROUTE_PATH}/edit`,
    name: 'private/wall.edit',
    component: () => import('./edit2/EditPersonalView2.vue'),
  },

  {
    path: `/${ROUTE_PATH}/favorite`,
    name: 'private/wall.favorite',
    component: () => import('./favorite/PersonalFavoriteView.vue'),
  },

  {
    path: `/${ROUTE_PATH}/follow`,
    name: 'private/wall.follow',
    component: () => import('./follow/PersonalFollowView.vue'),
  },

  {
    path: `/${ROUTE_PATH}/share`,
    name: 'public/wall.share',
    component: () => import('./share/PersonalShareView.vue'),
  },
  {
    path: `/${ROUTE_PATH}/template`,
    name: 'private/wall.template',
    component: () => import('./createCard/template/templateCardView.vue'),
  },
  {
    path: `/${ROUTE_PATH}/createpersonal`,
    name: 'private/wall.createpersonal',
    component: () => import('./createCard/personal/createPersonalCardView.vue'),
  },
  {
    path: `/wall/editprice`,
    name: 'private/wall.editprice',
    component: () => import('./createCard/editprice/editPriceView.vue'),
  },
];
