import mitt from 'mitt';
import { createPlugin } from '..';
import type { TGlobalEvent } from './index.types';

export const eventPlugin = (app: any) => {
  const instance = mitt<{ [key: string]: any }>();

  const newInstance: TGlobalEvent = {
    on(from, event, handler) {
      instance.on(`${from}.${event.toString()}`, handler);
    },

    emit(from, event, data) {
      instance.emit(`${from}.${event.toString()}`, data);
    },

    off(from, event, handler) {
      instance.off(`${from}.${event.toString()}`, handler);
    },
  };
  app.use(createPlugin('$mitt', () => newInstance));
};
