import { createApp } from 'vue';
import { createPinia } from 'pinia';

import App from './App.vue';
import router from './router';
import { i18nPlugin } from './plugins/i18n';
import { socketPlugin } from './plugins/socket';
import { eventPlugin } from './plugins/event';
import { createMetaManager } from 'vue-meta';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import { post } from './helper/request';

const app = createApp(App);

app.use(createPinia());
app.use(router);
app.use(socketPlugin);
app.use(eventPlugin);
app.use(i18nPlugin);
app.use(createMetaManager());
app.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCOrJflNFJ77Ore0AWMeGaNQTuOJnhckio',
    libraries: 'places',
    language: 'vi',
  },
});

app.config.errorHandler = (error, instance) => {
  console.error(error);

  const path = instance?.$route.fullPath;

  if (error instanceof Error) {
    post('/wapi/logs', {
      level: 'error',
      from: instance?.$options.name || '',
      path: path,

      data: {
        message: error.message,
        name: error.name,
        stack: error.stack,
      },
    });
  } else {
    post('/wapi/logs', {
      level: 'error',
      from: instance?.$options.name || '',
      path: path,

      data: error,
    });
  }
};

app.mount('#app');
