import { HOME_ROUTE } from '@/views/home/HomeView.route';
import { DETAIL_ROUTE } from '@/views/detail/DetailView.route';
import { SETTING_ROUTE } from '@/views/setting/SettingView.route';
import { createRouter, createWebHistory } from 'vue-router';
import { validateAccess } from './helper';
import { ADMIN_ROUTE } from '@/views/admin/AdminView.route';
import { WALL_ROUTE } from '@/views/wall/WallView.route';
import { SEARCH_ROUTE } from '@/views/search/SearchView.route';
import { TRANSACTION_ROUTE } from '@/views/transaction/TransactionView.route';
import { LIST_SERVICE_ROUTE } from '@/views/service/ListService.route';

const router = createRouter({
  history: createWebHistory(),
  routes: [
    //
    HOME_ROUTE,
    //
    DETAIL_ROUTE,
    //
    SETTING_ROUTE,
    //
    ADMIN_ROUTE,
    //
    ...WALL_ROUTE,
    //
    SEARCH_ROUTE,

    LIST_SERVICE_ROUTE,

    ...TRANSACTION_ROUTE,
  ],
});

router.beforeEach(async (to) => {
  const nameOfToRoute = to.name || '';
  return await validateAccess(nameOfToRoute, 'access');
});

export default router;
