import { userStore } from '@/stores/user';
import { io } from 'socket.io-client';
import { createPlugin } from '../index';
import type {
  ISocketClient,
  ISocketPlugin,
  ISocketPluginOption,
} from './socket.types';

export class SocketPlugin implements ISocketPlugin {
  socket: ISocketClient = io(socketUri, {});

  constructor(private options?: ISocketPluginOption) {
    this.attachEvent();
  }

  get socketId(): string {
    return this.socket?.id || '';
  }

  // https://socket.io/docs/v4/client-socket-instance/#lifecycle
  private attachEvent() {
    this.socket.once('connect', () => {
      // connected
      this.connectToServer();
    });
    this.socket.once('connect_error', (error: any) => {
      console.error(error);

      // must manual reconnect
    });
  }

  private connectToServer() {
    const user = userStore();
    if (!user.isValidN100) {
      return;
    }
    const data = {
      FO100: user.getSessionField('FO100', 0) || 0,
      devices: window.device,
      flatform: 'web',
    };
    this.socket.emit('connectToServer', data, (isSuccess: boolean) => {
      console.log(isSuccess);
    });
  }
}

export const socketPlugin = createPlugin(
  '$socket',
  (options) => new SocketPlugin(options)
);

const socketUri: string = import.meta.env.VITE_SOCKET_URI || '';
