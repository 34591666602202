import type { RouteRecordRaw } from 'vue-router';

export const TRANSACTION_ROUTE: RouteRecordRaw[] = [
  {
    path: '/transaction/',
    name: 'public/transaction.index',
    component: () => import('./request/TransactionRequest.vue'),
  },

  {
    path: '/transaction/invoice',
    name: 'private/transaction.invoice',
    component: () => import('./invoice/TransactionInvoice.vue'),
  },

  {
    path: '/transaction/request',
    name: 'public/transaction.request',
    component: () => import('./request2/TransactionRequest2.vue'),
    children: [
      {
        path: '/transaction/request/all',
        name: 'public/transaction_request.all',
        component: () => import('./request2/all/TransactionRequestAll.vue'),
      },
      {
        path: '/transaction/request/owner',
        name: 'public/transaction_request.owner',
        component: () => import('./request2/owner/TransactionRequestOwner.vue'),
      },
      {
        path: '/transaction/request/business',
        name: 'public/transaction_request.business',
        component: () =>
          import('./request2/business/TransactionRequestBusiness.vue'),
      },
      {
        path: '/transaction/request/affiliate',
        name: 'public/transaction_request.affiliate',
        component: () =>
          import('./request2/affiliate/TransactionRequestAffiliate.vue'),
      },
      {
        path: '/transaction/request/detail',
        name: 'public/transaction_request.detail',
        component: () =>
          import('./request2/detail/TransactionRequestDetail.vue'),
      },
      {
        path: '/transaction/request/detailAffiliate',
        name: 'public/transaction_request.detailAffiliate',
        component: () =>
          import('./request2/detailAffiliate/TransactionDetailAffiliate.vue'),
      },
    ],
  },

  {
    path: '/transaction/submit',
    name: 'private/transaction.submit',
    component: () => import('./submit/TransactionSubmit.vue'),
  },
];
